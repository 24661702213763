import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import BookingForm from "../components/BookingForm"
import Testimonials from "../components/Testimonials"

const getBooking = graphql`
  query {
    allContentfulTreatment {
      nodes {
        title
        price
      }
    }
    fileName: file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(getBooking)

  var treatmentList = data.allContentfulTreatment.nodes.map((item, index) => {
    return item.price.map((value, index) => {
      return `${item.title}-${value}`
    })
  })

  return (
    <Layout>
      <Banner
        title="Book Appointment"
        ishome={false}
        image={data.fileName.childImageSharp.fluid}
      />
      <main>
        <div className="main-content">
          <div className="c-book">
            <div className="container">
              <div className="row">
                <div className="c-book__intro">
                  <p className="p-large">
                    If you would like to book and appointment
                    <br />
                    email us at{" "}
                    <a href="mailto:step2wellbeing@gmail.com">
                      step2wellbeing@gmail.com
                    </a>
                    , <br />
                    call us on 07872907041, <br /> or please complete the form
                    bellow and well get back to you shortly.
                  </p>
                </div>
                <BookingForm treatmentList={treatmentList} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Testimonials />
    </Layout>
  )
}
